exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-ranking-ubezpieczen-js": () => import("./../../../src/pages/ranking-ubezpieczen.js" /* webpackChunkName: "component---src-pages-ranking-ubezpieczen-js" */),
  "component---src-pages-strefa-wiedzy-js": () => import("./../../../src/pages/strefa-wiedzy.js" /* webpackChunkName: "component---src-pages-strefa-wiedzy-js" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-ranking-jsx": () => import("./../../../src/templates/ranking.jsx" /* webpackChunkName: "component---src-templates-ranking-jsx" */)
}

